
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	DimensionData,
	DimensionMap,
	NumberRangeOperator,
	PermissionsGroup,
} from '@/store/models.def';
import { FdRef } from '@/worker/fd/FilteredDatabaseRef';
import uuid from 'uuid';
import { StateHistory } from '@/util/stateHistory';
import accountx from '@/store/modules/accountx';

@Component({
	components: {},
})
export default class BaseCodedWidget extends Vue {
	public get permissionIds(): PermissionsGroup[] {
		return [];
	}
	public get expensiveHook(): string {
		return '';
	}
	public static cloneDimensionDatas(dd: DimensionData) {
		const clone = { ...dd };
		clone.value = dd.value ? ([...dd.value] as any) : undefined;
		clone.subDimension = dd.subDimension ? [...dd.subDimension] : undefined;
		return clone;
	}

	public static limitSlices(svts: DimensionData[], maxSlices: number = 0) {
		svts.sort((a, b) => b.sum - a.sum);

		if (maxSlices === 0 || maxSlices >= svts.length) {
			return svts;
		}
		const otherIndex = svts.findIndex((svt) => svt.text === 'Others');
		const reserveForOthers = otherIndex === -1 || otherIndex >= maxSlices;
		if (reserveForOthers) {
			maxSlices -= 1;
		}
		const { filterType, filterKey } = svts[0];
		const others: DimensionData =
			otherIndex !== -1
				? svts[otherIndex]
				: { text: 'Others', value: [], sum: 0, filterType, filterKey };
		if (otherIndex !== -1) {
			svts[otherIndex].value = [...svts[otherIndex].value] as any;
		}
		for (let i = maxSlices; i < svts.length; i++) {
			if (i === otherIndex) {
				continue;
			}
			const toMerged = svts[i];
			if (filterType === 'string') {
				const toMergedStringList = toMerged.value as string[];
				(others.value as string[]).push(...toMergedStringList);
			} else {
				others.value = [];
			}
			others.sum += toMerged.sum;
			if (toMerged.subDimension) {
				const toMergedSubDimension = toMerged.subDimension;
				if (!others.subDimension) {
					others.subDimension = [];
				}
				const otherSubDimension = others.subDimension;
				for (let j = 0; j < toMergedSubDimension.length; j++) {
					const toMergeSub = toMergedSubDimension[j];
					if (!others.subDimension[j]) {
						others.subDimension[j] = BaseCodedWidget.cloneDimensionDatas(toMergeSub);
					} else {
						others.subDimension[j].sum += toMergeSub.sum;
					}
				}
			}
		}
		const result = [...svts.slice(0, maxSlices)];
		if (reserveForOthers) {
			result.push(others);
		}
		result.sort((a, b) => b.sum - a.sum);
		return result;
	}

	public currentJobId: string = '';
	public loading = true;
	public loadingText = '';

	public beforeDestroy() {
		this.currentJobId = '';
	}
	@Watch('expensiveHook', { immediate: true })
	public async expensiveTriggered() {
		try {
			const jobId = (this.currentJobId = uuid.v4());
			if (await this.loadHistory()) {
				this.currentJobId = '';
				this.loading = false;
				return;
			}
			this.checkInterrupted(jobId);

			// check permission
			if (this.permissionIds.length > 0 && !accountx.myPermissions) {
				return;
			}
			const myPermissions = accountx.myPermissions!;
			for (const perm of this.permissionIds) {
				if (!myPermissions[perm]) {
					return;
				}
			}

			this.loading = true;
			this.generateLoadingText(0);
			await this.expensiveCalc();
			this.loading = false;
		} catch (e) {
			if ((e as Error).message !== 'Interrupted') {
				console.error(e);
				this.loading = false;
			}
		}
	}

	public async expensiveCalc() {}

	public loadHistory() {
		const tag = (this.$options as any)._parentVnode.tag;
		const url = this.$route.path;
		const state = StateHistory.loadState(tag, url, this.expensiveHook);
		if (state) {
			for (const key in state) {
				if (state.hasOwnProperty(key)) {
					const data = state[key];
					this[key] = data;
				}
			}
			return true;
		}
		return false;
	}

	public saveHistory(...keys: string[]) {
		const tag = (this.$options as any)._parentVnode.tag;
		const url = this.$route.path;
		const state: any = {};
		for (const key of keys) {
			state[key] = this[key];
		}
		StateHistory.saveState(state, tag, url, this.expensiveHook);
	}

	public generateLoadingText(ratio: number) {
		this.loadingText = `${(ratio * 100).toFixed(1)}%`;
	}
	public checkInterrupted(jobId: string) {
		if (this.currentJobId !== jobId) {
			throw Error('Interrupted');
		}
	}

	protected async _sumJob(
		ref: FdRef,
		progressFactor: number,
		progressSoFar: number,
		key: string = 'amount',
	) {
		const jobId = (this.currentJobId = uuid.v4());
		const pa = progressFactor;
		const pb = progressSoFar;

		const head = await ref.get();
		this.generateLoadingText(0.5 * pa + pb);
		this.checkInterrupted(jobId);
		const sum = await head.getSum(key);

		this.generateLoadingText(pa + pb);
		this.checkInterrupted(jobId);
		return sum;
	}

	protected async _countJob(
		ref: FdRef,
		progressFactor: number,
		progressSoFar: number,
	) {
		const jobId = (this.currentJobId = uuid.v4());
		const pa = progressFactor;
		const pb = progressSoFar;

		const head = await ref.get();
		this.generateLoadingText(0.5 * pa + pb);
		this.checkInterrupted(jobId);
		const sum = await head.getCount();

		this.generateLoadingText(pa + pb);
		this.checkInterrupted(jobId);
		return sum;
	}

	protected async _loadDimension(
		ref: FdRef,
		options: DimensionMap[],
		progressFactor: number,
		progressSoFar: number,
		sumFunction:
			| ((ref: FdRef, pa: number, pb: number) => Promise<DimensionData[]>)
			| ((ref: FdRef, pa: number, pb: number) => Promise<number>)
			| string = 'amount',
	) {
		const results: DimensionData[] = [];
		const len = options.length;

		if (typeof sumFunction === 'string') {
			const key = sumFunction;
			sumFunction = (ref2, paa, pbb) => this._sumJob(ref2, paa, pbb, key);
		}
		const pa = progressFactor / len;
		let pb = progressSoFar;
		for (let i = 0; i < len; i++) {
			const option = options[i];
			const myRef = ref.clone();

			if (option.filterType === 'string') {
				if (typeof option.value === 'function') {
					const jobId = (this.currentJobId = uuid.v4());
					option.value = await option.value();
					this.checkInterrupted(jobId);
				}
				myRef.includes(option.filterKey, option.value as string[]);
			} else {
				if (typeof option.value === 'function') {
					const jobId = (this.currentJobId = uuid.v4());
					option.value = await option.value();
					this.checkInterrupted(jobId);
				}
				myRef.numberRange(option.filterKey, option.value as NumberRangeOperator);
			}
			const { value, text, filterKey, filterType } = option;

			const sub = await sumFunction(myRef, pa, pb);
			// const sub = await this._sumJob(ref, pa, pb, 'amount');
			// const sub = 0;

			if (typeof sub === 'number') {
				results.push({ value, text, filterKey, filterType, sum: sub });
			} else {
				const subDimension = sub as DimensionData[];
				const sum = subDimension.reduce((num, data) => num + data.sum, 0);
				results.push({ value, text, filterKey, filterType, sum, subDimension });
			}
			pb += pa;
			this.generateLoadingText(pb);
		}
		return results;
	}
}
