
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import uuid from 'uuid';
import { Dictionary } from 'vue-router/types/router';

@Component({
	components: {},
})
export default class DataInfo extends Vue {
	@Prop() public readonly name!: string;

	public id = uuid.v4();

	public get info() {
		return this.dic[this.name] || 'No info';
	}

	public dic: Dictionary<string> = {
		// GENERAL
		coming_soon: 'Coming Soon',

		// Dashboard
		dsh_todaySales:
			'Sales invoice amount and number of documents of selected date, and compared to a 7-day average. <br> Credit note is optional to be included ',
		dsh_todayProfit:
			'Sales profit of selected date, compared to a 7-day average. <br> Credit note is optional to be included ',
		dsh_todayQuotation:
			'Amount and number of quotations issued of selected date, compared to a 7-day average.',
		dsh_stockGroupPerformance:
			'Stock group sales performance for 7 days trailing.',
		dsh_todayCustomerPayment: 'Customer payment receive today.',
		dsh_todaySupplierPayment: 'Today payment to supplier.',
		dsh_customerDue: 'Customer documents due based on selected day.',
		dsh_supplierDue: 'Supplier documents due based on selected day.',
		dsh_currentMonthSales:
			'Sales, profit and credit note data within the month based on selected date. Information is a daily breakdown. <br> Summary table can check total amount',
		dsh_currentMonthQuotation:
			'Quotation and converted quotation within the month based on selected date. Information is a daily breakdown. <br> Summary table can check total amount',
		dsh_cashOnHand: 'Cash on hand amount, as of selected date',
		dsh_cashInBank: 'Cash in bank amount, as of selected date',
		dsh_netCashFlow: 'Net cash flow amount based on selected date.',
		dsh_cashInFlow: 'Cash in amount based on selected date.',
		dsh_cashOutFlow: 'Cash out amount based on selected date.',
		dsh_todaySalesAgent:
			'Daily sales agent ranking by billing amount of selected date. <br> Credit note and tax are optional to be included',
		dsh_todaySalesCustomer:
			'Daily customer sales ranking by billing amount of selected date. <br> Credit note and tax are optional to be included.',

		// Sales
		sl_monthSales:
			'Amount based on Customer Invoice and Customer Debit Note in SQL Account. <br> Amount based on selected date range. <br> Number of customers who contributed to sales for the date range. <br> Credit note is optional to be included. ',
		sl_monthSalesProfit:
			'Sales profit or margin based on selected date range. <br> Credit note is optional to be included.',
		sl_ytdSalesProfit:
			'Year to date sales amount which is from start of the year till the end of selected month. <br> Number of customers who contributed to sales for the year-to-date. <br> Credit note is optional to be included in calculation.',
		sl_salesTrend:
			'Trend of sales, profit and credit note in different perspective based on selected timeframe. <br> Data is always compared with the previous year.',
		sl_monthTopAgents:
			'Top 5 sales agent and remaining agents based on selected date range. <br> Credit note is optional in the calculation.',
		sl_monthTopCustomers:
			'Top 5 customers and remaining amounts based on selected date range. <br> Credit note is optional in the calculation.',
		sl_salesAnalysis:
			'Sales based on selected month and presented with different grouping options. <br> Credit note and Debit note is optional in the calculation.',
		sl_openQuotationTracking: 'Open quotation documents as of the selected date range',
		sl_pendingSalesOrder: 'Pending sales order documents as of the selected date range',
		sl_activeCustomer:
			'Number of customers that had made purchases based on selected year. This including total sales made and average sales per customer. <br> Credit note is optional in the calculation.',
		sl_existingCustomer:
			'Number of returned customers who previously made purchases based on selected year. <br> Credit note is optional in the calculation.',
		sl_newCustomer:
			'Number of new customers acquired based on selected year. <br> Credit note is optional in the calculation.',
		sl_monthNewCustomer:
			'New customer that was acquired in the selected month, based on selected year. <br> Credit note is optional in the calculation.',
		sl_customerRetention:
			'Last sales transaction of each customer in selected month, based on selected year',
		sl_convertedQuotation:
			'Number of quotation transferred partial/fully to sales invoice. <br> Amount is transferred value only.',
		sl_totalQuotation:
			'Number of quotation documents and total quotation amount.',
		sl_openQuotation:
			'Value of quotation yet to transfer or cancel, with total amount.',
		sl_salesProfitLoss:
			'Sales trend based on selected period. Amount based on Sales Invoice and Cash Sales in SQL Account. <br> Credit note and tax are optional in the calculation.',
		sl_salesOrderTrend: 'Sales order trend based on selected period.',
		sl_agentQuotationConversionRate:
			'Individual agent\'s sales conversion based on selected period and sort by conversion rate in descending order.',
    sl_agentOustandingRatio:
			'Individual agent\'s sales that are outstanding based on selected period',
		sl_agentQuotationOutstandingnRate:
				'Individual agent\'s sales outstanding based on selected period and sort by outstanding rate in ascending order.',
		sl_agentTotalSales:
			'Sales amount by each sales agent with breakdown of top 5 (overall) item group. <br> Ranking is sort by total sales amount in descending order. Tax is optional in the calculation.',
		sl_salesComparison:
			'Comparing sales based on selected periods and compare to each other. <br> Credit note is optional in the calculation.',
		sl_metric_totalSales:
			'Total sales amount for the entire year. <br> Number in bracket is the number of billing (case). <br> Eg. RM 50,000 (20) means whole year sales are RM 50,000 from 20 sales invoices / cash sales.',
		sl_metric_averageSalesPerCase:
			'Average sales value for each billing (case).',
		sl_metric_averageSalesPerMonth: 'Average how much sales value per month',
		sl_metric_averageSalesCasePerMonth: 'Average how many billing per month',
		sl_metric_averageSalesCycle:
			'Average how many days taken to make a sale. <br> E.g. 20 days means there will be a sale made every 20 days.',
		sl_metric_totalQuotation:
			'Total quotation amount for the entire year. <br> Number in bracket is the number of quotation documents (case). <br> Eg. RM 100,000 (10) means whole year quoted value RM 100,000 in 10 quotations.',
		sl_metric_averageQuotationCasePerMonth:
			'Average how many quotation generate per month',
		sl_metric_winRate:
			'Percentage of converted quotation against outstanding quotation. <br> Cancelled quotations are excluded.',
    sl_toDeliver:
      'List of orders that should be delivered sorted by how far off they are from the expected delivery day.<br><br> - OS Item represents the number of items that are overdue, not the total quantity overdue.',
    sl_fulfilledSalesOrders:
      'List of orders that are fulfilled.<br><br>- In the case of actual delivery date being invalid, the system will treat the date as 01/01/1970.<br>- Global date filter is based on SO "Expected Delivery".',

		// Agent
		agent_ytdRevenueContribution:
			'Year to date based on selected month and selected agent sales as a percentage to company total sales. <br> Credit note is optional in the calculation.',
		agent_ytdSalesProfitMargin:
			'Year to date sales profit or margin by selected agent. Can toggle between profit amount and profit margin. <br> Credit note is optional in the calculation.',
		agent_revenueContribution:
			'Sales as a percentage to company total sales based on selected month and agent. <br> Credit note is optional in the calculation.',
		agent_activeCustomer:
			'Number of customers this agent had sold to in the past 12 months from report date.',
		agent_ytdSales:
			'Year to date sales amount. <br> From start of the year till the end of report month.',

		// Customer
		customer_ytdSales:
			'Year to date sales amount, from start of the year till the end of report month. <br> Sales rank is based on among existing customers',
		customer_ytdSalesProfitMargin:
			'Year to date selected agent sales as a percentage to company total sales. <br> Profit amount and profit margin can be toggled.',
		customer_allTimeTrade:
			'Grand total of trade value with this customer. <br> Average annual sales is based on sales in previous year, current year (report date) sales not included.',
		customer_monthSales:
			'Sales amount of the report month by selected customer. <br> Ranking is based among all existing customers on that month that have sales with.',
		customer_monthSalesProfitMargin:
			'Sales profit or margin of the report month by selected customer. <br> Can toggle between profit amount and profit margin',
		customer_revenueContribution:
			'Year to date customer sales as a percentage to company total sales.',
		customer_salesTrend:
			'Monthly sales trend based on Cash Sales & Sales Invoice.',
		customer_totalOutstanding:
			'Total customer outstanding amount as of the selected date. <br> Outstanding amount based on knock off value.',
		customer_totalDue: 'Total customer due amount as of the selected date.',
		customer_overdue: 'Percentage of due amount over outstanding.',
		customer_customerOutstanding:
			'Proportion of each customer outstanding amount as of selected date. <br> Outstanding amount based on knock off value.',
    customer_customerOverdue:
			'Proportion of each customer overdue amount as of selected date. <br> Overdue amount based on knock off value.',
		customer_upcomingDue:
			'Customer documents that will be due in selected period. <br> Only outstanding amount will be shown.',
		customer_unappliedAmount:
			'Breakdown of un-applied amount by document type.',
		customer_agingOverview:
			'Customer aging report based on document date. <br> Aging amount based on knock off value.',
    customer_dueOverview:
			'Customer aging report based on due date. <br> Aging amount based on knock off value.',
		customer_analysis:
			'Customer trade analysis based on Customer Invoice & Customer Debit Note.',
		customer_documentAnalysis: 'Summary of different document type billings.',
		// tslint:disable-next-line:max-line-length
		customert_paymentAnalysis:
			'Customer payment analysis of all customer due within a calendar year. <br><br>Legend: <br> On Time - Amount were collected on time before due date.<br>30 Days - Amount were collected late up to 30 days after due date.<br>> 30 Days - Amount were collected more than 30 days after due date.<br>Unapplied - Amount pending to knockoff against invoices.',

		// Purchase
		purchase_ytdPurchase:
			'Year to date purchase amount, from start of the year till the end of report month.',
		purchase_currentMonthPurchase:
			'Current month purchase amount based on selected month.',
		purchase_purchaseTrend: 'Monthly purchase trend based.',
		purchase_purchaseAnalysis:
			'Selected month purchase presented with different grouping options.',
		purchase_itemSalesBySupplier:
			'Filtered supplier(s) to see what they supplied within the selected period.',
		purchase_totalPurchase:
			'Purchase amount after deduct purchase return, based on selected date range.',
		purchase_totalCreditNote:
			'Purchase return amount based on selected date range.',
		purchase_totalPurchaseOrder:
			'Purchase order amount based on selected date range.',
		purchase_purchaseComparison:
			'Comparing purchase amount based on selected period.',
		// sl_topSalesMonthly:
		// 	'Total sales of selected month, with the top sales agent and his/her share',
		// sl_topSalesQuarterly:
		// 	'Total quarterly sales in selected month, with the top sales agent and his/her share.',
		// sl_topSalesYearly:
		// 	'Total yearly sales in selected month, with the top sales agent and his/her share.',
		// sl_agentOutstandingQuotation:
		// 	"Individual agent's open quotation value within selected period, with breakdown value for stock group",
		// sl_personalAgentRankingReport: 'Agent sales ranking within company',
		// sl_personalAgentTopStockGroupSales:
		// 	"Agent's top selling stock group by amount",
		// sl_personalAgentTopFiveOutstanding:
		// 	'Top 10 account with most open quotation',

		// Stock
		sg_salesHistory:
			'Sales performance of stock groups within selected period.',
		sg_purchaseHistory:
			'Purchase history of stock groups within selected period.',
		sg_profitByStockGroup:
			'Sales profit and profit margin for each stock group within selected period <br> Profit calculation based on Item. Ref Cost in SQL account.',
		sg_itemSalesBreakdown: 'Breakdown of sales amount by stock item',
		stk_profitByStockItem:
			'Estimated sales profit and profit margin for each stock item. Profit calculation based on Item. <br> Tax is optional in the calculation.',
		sg_bestSellingStockItem: 'Top 20 best selling stock item by amount',
		stk_stockGroupPurchase:
			'Purchase of each stock group. Can view by amount or quantity. <br> Tax is optional in the calculation. <br> Negative sales amounts will not be displayed.',
		stk_stockGroupSales:
			'Sales of each stock group. View by amount or quantity. <br> Tax is optional in the calculation <br> Negative sales amounts will not be displayed.',
		stk_stockMovement:
			'In and out movement of stock items within selected period.',
		stk_stockAvailability:
			'Available stock as of selected date. <br> Reorder advice is based on Reorder Level in SQL Account. <br> Please be aware on dashboard Last Synced status',
    stk_stockBatchExpiry:
      'Expiry status of each stock batch.',
		stk_stockAging:
			'Stock aging by amount or by quantity. <br> Aging based on first in first out method.',
		stk_salesPurchaseMovement:
			'Average Sales and Purchase price for a stock item. <br> With transaction volume in each month.',
		stk_listOfBuyers: 'List of company who bought this item in selected year.',
		stk_itemSalesByCustomer:
			'Items bought by selected customer(s) based on selected month. <br> Credit note is optional in the calculation.',
		stk_metric: 'List of company who bought this item in selected year.',
		stk_metric_totalSales:
			'Total sales value and quantity of the product within selected year.',
		stk_metric_stockGroupSalesPercentage:
			'Sales contribution (by value) of this product within same stock group.',
		stk_metric_productSalesPercentage:
			'Sales contribution (by value) of this product in overall product sales.',
		stk_metric_averageUnitDeal: 'Average selling unit per transaction',
		stk_metric_averageUnitMonth: 'Average selling unit per month.',
		stk_metric_bestMonth:
			'Highest monthly sales (by value) within selected year. <br> If there are more than 1 month with same value, the earliest month will display.',
		stk_metric_lowestMonth:
			'Lowest monthly sales (by value) within selected year. <br> Report only covers months that have sales. <br> If there are more than 1 month with same value, the latest month will display.',
        stk_closingStockByLocation: 'Closing Stock sorted by location.',

		// Customer
		cst_customerLocations:
			'Location of customer based on coordinates saved in SQL Account.',
		cst_agingSummary:
			'Summary of customer aging by period. <br> Aging period based on document date. <br> Aging amount based on knock off value.',
		cst_averageValuePerTransaction: 'Average billing value',
		cst_averageUnitPerTransaction: 'Average unit in each billing',
		cst_tradeOverRevenue:
			'Percentage of customer net sales amount over total net sales amount in PnL. <br> (PnL net sales amount include non-customer sales)',
		cst_periodCollection: 'Collection status for AR due in selected period.',
		cst_paymentPunctuality:
			'This chart shows the punctuality of customer making payments. <br><br> Legend: <br> On-Time - Number of documents that fully paid before due date. <br> On-Buffer - Number of documents that fully paid within 1 week after due date. <br> Late - Number of documents that fully paid 2 week after due date but no longer than 5 weeks. <br> Very Late - Number of documents that fully paid more than 5 weeks after due date. <br> <br> eg. If you have On-Time: 150 (75.32%) means that there are 150 invoices payments were collected on time, representing 75.32% of all documents that had completely paid. <br> *Documents that are still outstanding is excluded.',

		// Supplier
		sup_supplierLocations:
			'Location of supplier based on coordinates saved in SQL Account.',
		sup_totalOutStanding:
			'Total outstanding amount to supplier as of the selected date.',
		sup_totalDue: 'Total due amount to supplier as of the selected date.',
		sup_overdue: 'Percentage of due amount over outstanding.',
		sup_supplierOutstanding:
			'Proportion of outstanding amount to each supplier as of selected date.',
		sup_agingSummary:
			'Summary of supplier aging by period. <br> Aging based on document date.',
		sup_agingOverview: 'Supplier aging report based on document date.',
		sup_averageValuePerTransaction:
			'Average purchase value for each transaction (document).',
		sup_averageUnitPerTransaction:
			'Avarage purchase unit in each transaction (document).',
		sup_tradeOverRevenue:
			'Percentage of net purchase amount of selected supplier over total net purchase amount in PnL.',
		sup_purchaseComparison: 'Comparing net purchase in selected period.',
		sup_upcomingDue:
			'Supplier documents that will be due in selected period. <br> Only outstanding amount will be shown.',
		sup_documentAnalysis: 'Summary of different document type billings.',
		sup_periodDuePayment: 'Payment status for AP due in selected period.',
		sup_unappliedAmount: 'Breakdown of un-applied amount by document type.',
		sup_paymentAnalysis:
			'Collection analysis of all supplier due within a calendar year. <br><br>Legend: <br> On Time - These amount were paid on time (paid within the due month).<br>30 Days - These amount were paid late up to 30 days after due date.<br>> 30 Days - These amount were paid at least 30 days after due date.<br>',
		sup_analysis:
			'Supplier trade analysis based on Supplier Invoice & Supplier Debit Note. <br> Optional to offset Supplier Credit Note',

		// GL
		gl_totalAssets: 'Total Assets as of selected date',
		gl_totalLiabilities: 'Total Liabilitites as of selected date',
		gl_equities: 'Total Equities as of selected date',
		gl_assets: 'Financial position in a chart (Assets)',
		gl_liabilities: 'Financial position in a chart (Liabilities & Equities)',
		gl_topDebtors: 'Highest outstanding debtors',
		gl_topCreditors: 'Highest outstanding creditors',
		gl_cashOnHand: 'Total amount in all Cash Account in SQL.',
		gl_cashInBank: 'Total amount in all Bank Account in SQL.',
		gl_expenses: 'Expenses in selected period',
		gl_cogs: 'Cost of Goods Sold in the selected period. (Not including Opening and Closing Stock)' +
				'<br>Separated by Type of particular is a Manufacturing Cost (MC).',
		gl_pnl: 'Profit/Loss in selected period',

		// BUSINESS PERFORMANCE
		bp_currentRatio:
			'Working capital ratio as of end of each month <br> Formula : ( Current Assets / Current Liabilities ) <br> Working capital ratio is up to current day.',
		bp_workingCapitalToTotalAssets:
			'Working Capital to Total Assets as of end of each month <br> Formula : ( Current Asset - Current Liabilities ) / Total Assets <br> Current month ratio is up to current day.',
		bp_debtRatio:
			'Liabilites ratio as of end of each month <br> Formula : ( Total Liabilities / Total Assets ) <br> Liabilites ratio is up to current day.',
		bp_fixedAssetsToNetWorthRatio:
			'Fixed Assets to Net Worth Ratio as of end of each month <br> Formula : Fixed Assets / ( Total Assets - Total Liabilities ) <br> Current month ratio is up to current day.',
		bp_currentLiabilitiesToNetWorthRatio:
			'Current Liabilities to Net Worth Ratio as of end of each month <br> Formula : Current Liabilities / ( Total Assets - Total Liabilities ) <br> Current month ratio is up to current day.',
		bp_cashRatio:
			'Cash & Bank ratio as of end of each month <br> Formula : ( Cash + Bank ) / Current Liabilities <br> Current month ratio is up to current day.',
		bp_assetsTurnover:
			'Net Sales / Total Assets <br> Current month ratio is up to current day. <br> Current month ratio is up to current day.',
		bp_debtToEquityRatio:
			'Tota Debt / Total Equity <br> Current month ratio is up to current day. <br> Current month ratio is up to current day.',
		bp_fixedAssetsTurnoverRatio:
			'Net Sales / [ ( Net Fixed Assets\' beginning balance - ending balance ) / 2 ] <br> Current month ratio is up to current day.',
		bp_receivableTurnover:
			'Annual Credit Sales / Accounts Receivable <br> Current month ratio is up to current day. <br> Current month ratio is up to current day.',
		bp_averageCollectionPeriod:
			'Accounts Receivable / ( Annual Credit Sales / 365days ) <br> Current month ratio is up to current day. <br> Current month ratio is up to current day.',
		bp_cashFlow:
			'Cash Level: Cash and cash in bank as of end of the month <br> Cash Flow: Monthly net inflow/outflow of cash <br> When current year is selected, monthly cash flow is up to current day. <br> Current month ratio is up to current day.',
		bp_cashInFlow:
			'Cash in transactions as of selected date',
		bp_cashOutFlow:
			'Cash out transactions as of selected date',
		bp_financial_revenueGrowth:
			'Growth of net sales compare to previous year. <br> Formula: (This year Net Sales - Previous year Net Sales) / This year Net Sales',
		bp_financial_grossMargin:
			'Percentage of Gross Profit to Net Sales. <br> Formula: Gross Profit / Net Sales',
		bp_financial_netMargin:
			'Percentage of Net Profit Before Tax to Net Sales. <br> Formula: Net Profit(Loss) / Net Sales',
		bp_financial_efficiencyRatio:
			'Percentage of Expenses to Net Sales. <br> Formula: Expenses / Net Sales',
		bp_financial_cashRatio: 'Formula : ( Cash + Bank ) / Current Liabilities',
		bp_financial_currentRatio:
			'Formula : ( Current Assets / Current Liabilities )',
		bp_financial_debtRatio: 'Formula : ( Total Liabilities / Total Assets )',
		bp_financial_returnOnEquity: 'Formula: Net Profit / Total Equity',
		bp_financial_leverageRatio: 'Formula: Liabilities / Total Equity',
		bp_financial_fixedAssetsToNetWorthRatio:
			'Formula : Fixed Assets / ( Total Assets - Total Liabilities )',
		bp_financial_currentLiabilitiesToNetWorthRatio:
			'Formula : Current Liabilities / ( Total Assets - Total Liabilities )',
		bp_financial_workingCapitalToTotalAssets:
			'Formula : ( Current Asset - Current Liabilities ) / Total Assets',
		bp_financial_inventoryTurnover: 'Formula: COGS / Average Inventory',
		bp_financial_totalAssetTurnover:
			'Formula: Net Sales / Average Total Assets',
		bp_financial_accountReceivableTurnover:
			'Formula: Net Sales / Account Receivable',
		bp_financial_accountPayableTurnover: 'Formula: Net Sales / Account Payable',
		bp_salesTracker:
			'Accumulated sales amount based on Cash Sales & Sales Invoice',
		bp_totalRevenue: 'Net Sales in current account period',
		bp_otherIncome: '(Total Other Income + Extra Ordinary Income) in current account period.',
		bp_cogs: 'Cost of goods sales in current account period',
		bp_grossProfit: '(Net sales - Total COGS) in current account period',
		bp_totalExpenses: 'Total expenses in current account period',
		bp_netProfitBeforeTax: '(Total Gross Profit + Other Income + Extra Ordinary Income - Total Expenses) in current account period',
		bp_salesPurchaseExpenses: 'Sales, purchase and expenses overview 1 year from the 2nd selected month.',

		// Comparison
		cp_item:
			'What you want to see/check/know ? <br> Example: I want to see <strong class="text-cyan">sales</strong>',
		cp_category:
			'Who/What is based on the item you have chosen just now. <br> Example: I want to see all <strong class="text-cyan">agents\'</strong> sales',
		cp_value:
			'Related to item chosen, and what you want to know more about that. <br> Example: I want to see all agents\' <strong class="text-cyan">total</strong> sales',

		// Sales Report - Summary
		sr_summary_ytdSales:
			'Year to date sales amount.  <br> From start of the year till the end of report month.',
		sr_summary_mthTopCustomer: 'Top 5 sales customer in the report month.',
		sr_summary_salesTrend:
			'Monthly sales trend based on Cash Sales & Sales Invoice. <br> Note: Non item sales (Customer Invoice) is not included.',
		sr_summary_salesTrend_column:
			'(All) - Sales from all customers. <br> (Existing) - Sales from existing customers. <br> (New) - Only includes sales from new customer in the acquired month',
		sr_summary_salesTrend_line:
			'Line presents data in running total (accumulates monthly).',

		// Sales Report - Growth
		sr_growth_existingCustomer:
			'Number of customers who previously made purchases also return during the report year.',
		sr_growth_newCustomer:
			'Number of new customers acquired within the report year.',
		sr_growth_customerGrowth:
			'Yearly comparison of sales breakdown by new or existing customers.',
		sr_growth_customerAnalysis:
			'Year to date customer sales presented with different grouping options.',

    // Sales Report - Analysis (Enhancements)
    sr_analysis_totalSalesByRegion: 'Total sales of each region. <br> Debit and Credit notes are optional.',
    sr_analysis_totalSalesComparisonByIndustry: 'Total sales of each industry (A.K.A. Company Category) in comparison with the last year. <br> Debit and Credit notes are optional.',
    sr_analysis_totalSalesComparisonByPeriod: 'Total sales of each month in comparison to the same period in the last year. <br> Debit and Credit notes are optional.',
    sr_analysis_totalSalesComparisonByRegion: 'Total sales of each region in comparison with the last year. <br> Debit and Credit notes are optional.',
    sr_analysis_percentOfSalesContributionByRegion: 'The percentage of sales of each region to the total sales of the year-to-month in comparison with the last year. <br> Debit and Credit notes are optional.',

		agent_monthlySales: 'Sales amount of the report month.',
		agent_monthlySalesProfitMargin:
			'Sales profit or margin of the report month.',
		agent_salesAnalysis:
			'Year to date agent sales presented with different grouping options. <br> Note: Non item sales (Customer Invoice) is not included.',
		agent_salesMetrics:
			'Yearly sales metrics of selected agent. <br> Current year data is up to report month only (YTD). <br> Note: Non item sales (Customer Invoice) is not included.',
		agent_salesMetrics_salesRank: 'Yearly sales ranking in respective years.',
		agent_salesMetrics_totalSales: 'Net sales amount of each year.',
		agent_salesMetrics_salesMargin: 'Sales margin of each year',
		agent_salesMetrics_revenueContribution:
			'Sales as a percentage to company total sales.',
		agent_salesMetrics_caseContribution:
			'Case as a percentage to company total case.',
		agent_salesMetrics_grossProfitContribution:
			'Sales profit as a percentage to company total sales profit.',
		agent_salesMetrics_totalCases:
			'Number of sales cases (documents) in each year.',
		agent_salesMetrics_averageSalesPerCase:
			'Average sales value for each case (document).',
		agent_salesMetrics_averageSalesCasePerMonth:
			'Average number of sales case (document) in a month.',
		agent_salesMetrics_averageSalesPerMonth: 'Average sales value in a month.',
		agent_salesMetrics_averageSalesCycle:
			'Average how many days taken to make a sale. <br> E.g. 4 days means, in average new sales made in every 4 days.',

		sr_customer_paymentPunctuality:
			'This chart shows the punctuality of customer making payments within report year.<br>Legend: <br>On-Time - Number of documents that fully paid before due date.<br>On-Buffer - Number of documents that fully paid within 1 week after due date.<br>Late - Number of documents that fully paid 2 week after due date but no longer than 5 weeks.<br>Very Late - Number of documents that fully paid more than 5 weeks after due date.',
		sr_customer_agingSummary: 'Aging summary in the past 3 months.',
		sr_customer_salesTrend_column:
			'Sales amount, sales profit, or payment from this customer in respective months.',
		sr_customer_salesTrend_line:
			'Line presents data in running total (accumulates monthly).',
		sr_customer_itemSalesAnalysis:
			'Item sales in different period presented with different grouping options. <br>Note: Non item sales (Customer Invoice) is not included.',

    // custom modules

    cm_instafresh_daily_sales_bar: 'Total sales up to selected date for each location.',
    cm_instafresh_daily_sales_table: 'Individual daily sales according to date.',
    cm_wls_credit_limit_analysis: 'Tier definition is as follows: <br> A = <10% late <br> B = >10% > <20% late <br> C = >20% late',
    cm_vivalec_closing_stock_by_group: 'Expandable closing stock groups.',
    cm_vivalec_stock_item_budgeting: 'Shows stock item budgeting status according to available budget. <br> > 0 = Available Budget to Order. <br> < 0 = No Available Budget To Order. <br> Else = None',
    cm_palmking_monthly_sales_table: 'Datatable showing sales, cost, margin, and margin percentage of each month.',
    cm_palmking_monthly_sales_chart: 'Monthly Sales chart separated by overseas and local.',
    cm_palmking_monthly_sales_company: 'Datatable showing sales sorted by company.,',
    cm_tiarco_yearly_same_period: 'A comparison chart using data from the previous year, and this year month-to-date to the month chosen.',
    cm_tiarco_yearly_same_period_table: 'A comparison table using data from the previous year, and this year month-to-date to the month chosen.',
    cm_tiarco_monthly_comparison: 'A month-to-date comparison chart data from this year up to the end of the month chosen.',
    cm_tiarco_monthly_comparison_table: 'A month-to-date comparison table using data from this year to the end of the month chosen.',
    cm_tiarco_other_income_breakdown: 'A descriptive breakdown of what is included in Other Income.',
    cm_tiarco_yearly_sales_analysis: 'Each customer purchased item in total value or qty. <br> Credit note & Debit note is optional to be included.',
    cm_nycx_discount_table_item: 'Discount table based on item-code selected.',
    cm_nycx_discount_table_all: 'Discount table for all items',
	};
}
