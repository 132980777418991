
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
@Component({
	components: {},
})
export default class BaseFilterableContainer extends Vue {
	@Prop({ default: () => [] }) public filterIds!: string[];
	@Prop({ default: () => [] }) public permissionIds!: string[];
	@Prop({ default: null }) public currentFocusingFilterId!: string;
	@Prop({ default: false }) public isComingSoon!: boolean;
	@Prop({ default: false }) public loading!: boolean;
	@Prop({ default: '' }) public loadingText!: string;

	public get dateFormatted() {
		if (this.date) {
			if (typeof this.date === 'string') {
				return this.date;
			} else if (typeof this.date === 'number') {
				return moment(this.date).format('DD MMM YYYY');
			} else if (this.date.length > 1) {
				return `${moment(this.date[0]).format('DD MMM YYYY')} - ${moment(
					this.date[1],
				).format('DD MMM YYYY')}`;
			} else {
				return `As of ${moment(this.date[0]).format('DD MMM YYYY')}`;
			}
		}
	}
	/**
	 * string -> same
	 * number -> convert to DD MMM YYYY
	 * [number] -> As of DD MMM YYYY
	 * [number, number] -> DD MMM YYYY - DD MMM YYYY
	 */
	@Prop({ default: () => [moment().valueOf()] })
	public readonly date!: [number, number?] | string | number;
	@Prop({ default: '' }) public readonly title!: string;
	@Prop({ default: '' }) public readonly dataInfoName!: string;
}
