
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';

@Component({
	components: {},
})
export default class BaseFilterable extends Vue {
	@Prop({ default: () => [] }) public filterIds!: string[];
	@Prop({ default: () => [] }) public permissionIds!: string[];
	@Prop({ default: null }) public currentFocusingFilterId!: string;
	@Prop({ default: false }) public isComingSoon!: boolean;
	@Prop({ default: false }) public loading!: boolean;
	@Prop({ default: '' }) public loadingText!: string;

	public isHover: boolean = false;

	public get myPermissions() {
		return accountx.myPermissions;
	}
	public get permited() {
		if (!this.myPermissions) {
			return false;
		}
		for (const required of this.permissionIds) {
			if (!this.myPermissions[required]) {
				return false;
			}
		}
		return true;
	}
	public get comingSoon() {
		return this.isComingSoon;
	}

	public get unfocused() {
		return (
			this.currentFocusingFilterId &&
			!this.filterIds.includes(this.currentFocusingFilterId)
		);
	}

	public get showLoading() {
		return this.permited && !this.comingSoon && this.loading;
	}
}
