
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseCodedWidget from './BaseCodedWidget.vue';
import uuid from 'uuid';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	GroupNameSumPair,
	ValueText,
	DimensionData,
	DimensionMap,
	NumberRangeOperator,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { FilteredDatabaseHead } from '@/worker/fd/FilteredDatabaseHead';
import moment, { Moment } from 'moment';
import { FdRef } from '@/worker/fd/FilteredDatabaseRef';
import { Dictionary } from 'vue-router/types/router';

@Component({
	components: {},
})
export default class FilterWidget extends BaseCodedWidget {
	public static limitSlices = BaseCodedWidget.limitSlices;

	public static filterGroupValueBySelectedStringList(
		group: string[],
		stringList: string[],
		fullStringListLength: number,
	): string[] {
		if (stringList.length === fullStringListLength) {
			return group;
		}
		return group.filter((itemCode) => {
			const index = stringList.indexOf(itemCode);
			if (index !== -1) {
				stringList.splice(index, 1);
				return true;
			}
			return false;
		});
	}
	@Prop({ default: () => [] }) public selectedAgents!: string[];
	@Prop({ default: () => [] }) public selectedCustomers!: string[];
	@Prop({ default: () => [] }) public selectedStockItems!: string[];
	@Prop({ default: () => [] }) public selectedSuppliers!: string[];
    @Prop({ default: () => [] }) public selectedProject!: string;
    @Prop({ default: () => [] }) public selectedProjects!: string[];
	@Prop({
		default: moment().startOf('day').valueOf(),
	})
	public selectedAsOfDate!: number;
	@Prop({
		default: () => [
			moment().startOf('day').add(-1, 'year').valueOf(),
			moment().endOf('day').valueOf(),
		],
	})
	public selectedDateRange!: [number, number];
	@Prop({ default: null }) public currentFocusingFilterId!: string | null;

	protected async _generateFilterDimensionMap(
		ref: FdRef,
		groupBy:
			| 'agents'
			| 'stockItems'
			| 'customers'
			| 'suppliers'
			| 'stockGroup'
			| 'customerCategory'
			| 'supplierCategory'
      | 'project',
		progressFactor: number,
		progressSoFar: number,
	): Promise<DimensionMap[]> {
		const myRef = ref.clone();
		const fo = myRef.filterOptions;

		let names: string[] = [];
		let nameDic: Dictionary<string>;
		let values: Array<string[] | (() => Promise<string[]>)> = [];
		let fk: string;
		let stringList: string[] | undefined;
		let vts: ValueText[];

		switch (groupBy) {
			case 'stockGroup':
				fk = 'itemCode';
				stringList = fo[fk] && fo[fk].stringList;
				names = gdbx.allStockGroups.map((vt) => vt.text);
				// here I passed in function instead of string[] so that later it will be
				// called only when it is needed instead of generating the values now altogether
				// to prevent lag.
				values = gdbx.allStockGroups.map((vt) =>
					!stringList
						? vt.value
						: async () =>
								FilterWidget.filterGroupValueBySelectedStringList(
									vt.value,
									stringList!,
									gdbx.allStockItems.length,
								),
				);
				break;
			case 'customerCategory':
				fk = 'code';
				stringList = fo[fk] && fo[fk].stringList;
				names = gdbx.allCustomerCategories.map((vt) => vt.text);
				values = gdbx.allCustomerCategories.map((vt) =>
					!stringList
						? vt.value
						: async () =>
								FilterWidget.filterGroupValueBySelectedStringList(
									vt.value,
									stringList!,
									gdbx.allStockItems.length,
								),
				);
				break;
			case 'supplierCategory':
				fk = 'code';
				stringList = fo[fk] && fo[fk].stringList;
				names = gdbx.allSupplierCategories.map((vt) => vt.text);
				values = gdbx.allSupplierCategories.map((vt) =>
					!stringList
						? vt.value
						: async () =>
								FilterWidget.filterGroupValueBySelectedStringList(
									vt.value,
									stringList!,
									gdbx.allStockItems.length,
								),
				);
				break;
			case 'agents':
				fk = 'agent';
				nameDic = gdbx.agentNames;
				vts = gdbx.allAgents;
				stringList = fo[fk] && fo[fk].stringList;
				names = getNames(nameDic, vts, stringList);
				values = getValues(vts, stringList);
				break;
			case 'stockItems':
				fk = 'itemCode';
				nameDic = gdbx.stockItemNames;
				vts = gdbx.allStockItems;
				stringList = fo[fk] && fo[fk].stringList;
				names = getNames(nameDic, vts, stringList);
				values = getValues(vts, stringList);
				break;
			case 'customers':
				fk = 'code';
				nameDic = gdbx.customerNames;
				vts = gdbx.allCustomers;
				stringList = fo[fk] && fo[fk].stringList;
				names = getNames(nameDic, vts, stringList);
				values = getValues(vts, stringList);
				break;
			case 'suppliers':
				fk = 'code';
				nameDic = gdbx.supplierNames;
				vts = gdbx.allSuppliers;
				stringList = fo[fk] && fo[fk].stringList;
				names = getNames(nameDic, vts, stringList);
				values = getValues(vts, stringList);
				break;
      case 'project':
        fk = 'code';
        nameDic = gdbx.projectNames;
        vts = gdbx.allProjects;
        stringList = fo[fk] && fo[fk].stringList;
        names = getNames(nameDic, vts, stringList);
        values = getValues(vts, stringList);
        break;
		}

		function getNames(
			nameDic2: Dictionary<string>,
			vts2: ValueText[],
			stringList2?: string[],
		) {
			if (stringList2) {
				return stringList2.map((code) => nameDic2[code]);
			}
			return vts2.map((vt) => vt.text);
		}
		function getValues(vts2: ValueText[], stringList2?: string[]) {
			if (stringList2) {
				return stringList2.map((code) => [code]);
			}
			return vts2.map((vt) => [vt.value]);
		}

		const pa = progressFactor;
		const pb = progressSoFar;
		this.generateLoadingText(pa + pb);

		return names.map((text, index) => ({
			text,
			filterKey: fk,
			filterType: 'string',
			value: values[index],
		}));
	}

	protected async _loadDimensionByFilters(
		ref: FdRef,
		groupBy:
			| 'agents'
			| 'stockItems'
			| 'customers'
			| 'suppliers'
			| 'stockGroup'
			| 'customerCategory'
			| 'supplierCategory',
		progressFactor: number,
		progressSoFar: number,
		sumFunction:
			| ((ref: FdRef, pa: number, pb: number) => Promise<DimensionData[]>)
			| ((ref: FdRef, pa: number, pb: number) => Promise<number>)
			| string = 'amount',
	) {
		let pa = 0.01 * progressFactor;
		let pb = progressSoFar;
		const options = await this._generateFilterDimensionMap(
			ref,
			groupBy,
			pa,
			pb,
		);
		pb += pa;
		pa = 0.99 * progressFactor;

		return this._loadDimension(ref, options, pa, pb, sumFunction);
	}

	protected async _generateDateDimensionMap(
		ref: FdRef,
		period: 'day' | 'month' | 'year' | 'last30-120days' | 'last30-150days',
		progressFactor: number,
		progressSoFar: number,
		dateKey: string = 'date',
	) {
		const myRef = ref.clone();
		const fo = myRef.filterOptions;

		const jobId = (this.currentJobId = uuid.v4());
		const pa = progressFactor;
		const pb = progressSoFar;

		let min: number | undefined;
		let max: number | undefined;
		const numberOperators = fo[dateKey] && fo[dateKey].numberOperators;

		if (numberOperators) {
			// if it's no 'last30-120days' and d1 is 0 or infinity,
			// need to get the min from current ref
			const d1 = numberOperators[0][1];

			if (period !== 'last30-120days') {
				if (d1 !== Number.NEGATIVE_INFINITY || d1 !== 0) {
					min = d1;
				}
			}
			// if date is = (single date), max = d1
			if (numberOperators[0][0] === '=') {
				max = d1;
			} else {
				// if not single date, 2 operator is must-have
				const d2 = numberOperators[1]![1];
				// if d2 is infinity, need to get max from current ref
				if (d2 !== Number.POSITIVE_INFINITY) {
					max = d2;
				}
			}
		}
		if (min === undefined || max === undefined) {
			const head = await ref.get();
			this.generateLoadingText(pa * 0.3 + pb);
			this.checkInterrupted(jobId);
			const minMax = await head.getMinMax('date');
			this.generateLoadingText(pa * 0.6 + pb);
			this.checkInterrupted(jobId);
			if (min === undefined) {
				min = minMax.min;
			}
			if (max === undefined) {
				max = minMax.max;
			}
		}
		const filterKey = dateKey;
		const filterType = 'number';
		const results: DimensionMap[] = [];
		let n0: number;
		let n1: number;
		let value: NumberRangeOperator;
		let mmt: Moment;

		if (period === 'last30-120days') {
			mmt = moment(max).endOf('day');
			n1 = mmt.valueOf();
			n0 = mmt.add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: 'Current', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 30 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 60 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 90 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = min;
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 120 Days', value, filterType, filterKey });

			this.generateLoadingText(pa + pb);
			this.checkInterrupted(jobId);
			return results;
		}

		if (period === 'last30-150days') {
			mmt = moment(max).endOf('day');
			n1 = mmt.valueOf();
			n0 = mmt.add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: 'Current', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 30 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 60 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 90 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = mmt.add(1, 'days').add(-30, 'days').startOf('day').valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 120 Days', value, filterType, filterKey });

			n1 = mmt.add(-1, 'days').endOf('day').valueOf();
			n0 = min;
			value = [
				['>=', n0],
				['<=', n1],
			];
			results.push({ text: '> 150 Days', value, filterType, filterKey });

			this.generateLoadingText(pa + pb);
			this.checkInterrupted(jobId);
			return results;
		}

		const periodFormat =
			period === 'day' ? 'DD MMM YYYY' : period === 'month' ? 'MMM YY' : 'YYYY';
		mmt = period === 'month' ? moment(min) : moment(min).startOf(period);
		let text = mmt.format(periodFormat);
		n0 = mmt.valueOf();
		n1 = mmt.endOf(period).valueOf();
		value = [
			['>=', n0],
			['<=', n1],
		];
		results.push({ text, value, filterType, filterKey });
		while (n1 < max) {
			n1 = mmt.add(1, period).endOf(period).valueOf();
			n0 = mmt.startOf(period).valueOf();
			value = [
				['>=', n0],
				['<=', n1],
			];
			text = mmt.format(periodFormat);
			results.push({ text, value, filterType, filterKey });
		}
		this.generateLoadingText(pa + pb);
		this.checkInterrupted(jobId);
		return results;
	}

	protected async _loadDimensionByPeriod(
		ref: FdRef,
		period: 'day' | 'month' | 'year' | 'last30-120days' | 'last30-150days',
		progressFactor: number,
		progressSoFar: number,
		sumFunction:
			| ((ref: FdRef, pa: number, pb: number) => Promise<DimensionData[]>)
			| ((ref: FdRef, pa: number, pb: number) => Promise<number>)
			| string = 'amount',
		dateKey: string = 'date',
	) {
		let pa = 0.01 * progressFactor;
		let pb = progressSoFar;
		const options = await this._generateDateDimensionMap(
			ref,
			period,
			pa,
			pb,
			dateKey,
		);

		pb += pa;
		pa = 0.99 * progressFactor;

		return this._loadDimension(ref, options, pa, pb, sumFunction);
	}
}
