export function roundToFixed(number: number, decimal: number = 2) {
	const mul = 10 ** decimal;
	return Math.round(number * mul) / mul;
}

export function addComma(number: number,
	                        decimal: number = 0,
	                        forceDecimal: boolean = false,
	                        fillDecimal: boolean = false) {
	const parts = roundToFixed(Math.ceil(number), decimal).toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	if (parts[1]) {
		parts[1] = parts[1].substr(0, decimal);
		while (parts[1].length < decimal && (fillDecimal || forceDecimal)) { parts[1] += '0'; }
	}
	return parts[0];
}
(window as any).addComma = addComma;
